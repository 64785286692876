import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocaleService } from '@core/services/locale/locale.service';
import { BaseForm, EntityTypeFieldDto, getEnumOptions } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';
import { pick } from 'lodash-es';

@Component({
  selector: 'app-entity-types-item-form',
  templateUrl: './entity-types-item-form.component.html',
  styleUrls: ['./entity-types-item-form.component.scss'],
})
export class EntityTypesItemFormComponent extends BaseForm<EntityTypeFieldDto> implements OnInit {
  categories = getEnumOptions(EntityTypeFieldDto.CategoryEnum);
  // treeViewModes = getEnumOptions(EntityTypeFieldDto.TreeViewModeEnum);

  // formRepeaterFields: IDynamicComponent[] = [];

  constructor(
    public viewModeService: ViewModeService,
    public localeService: LocaleService
  ) {
    super(viewModeService, 'ENTITY_TYPE');
    // this.initFormRepeaterFields();
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat(this.formatGetData())
      : this.formatGetChangedValues();
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }
  formatGetChangedValues() {
    let ret = this.getChangedFormValues().updateItems;
    let dFields = [];
    ret.forEach((rField) => {
      if (rField.key == 'fields') {
        rField.value?.forEach((field) => {
          field = pick(field, 'code', 'key', 'name', 'type', 'options', 'fieldCategory');
          dFields.push(field);
        });
        rField.value = dFields;
      }
    });
    return ret;
  }
  formatGetData() {
    let ret = { ...this.formGroup.getRawValue() };
    // let dFields = {};
    // ret.dynamicFields?.forEach(field=>{
    //     dFields[field.key] = field;
    // });
    // ret.dynamicFields = dFields;
    return ret;
  }
  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      assetType: new FormControl(null),
      icon: new FormControl(null),
      fields: new FormControl([]),
      category: new FormControl(null, Validators.required),
      // treeViewMode: new FormControl(null, Validators.required),
      description: new FormControl(null),
      // test: new FormControl([]),
    });
  }
  // initFormRepeaterFields() {
  //   this.formRepeaterFields = [
  //     {
  //       componentType: BasicInputComponent,
  //       options: {
  //         label: 'Label',
  //         name: 'label',
  //         control: new FormControl('', Validators.required),
  //         dataType: DataTypeEnum.Text,
  //         showLabelInViewMode: false,
  //       },
  //     },
  //     {
  //       componentType: DropDownInputComponent,
  //       options: {
  //         label: 'Select Type',
  //         name: 'type',
  //         control: new FormControl(null, Validators.required),
  //         inputOptions: {
  //           dropDownInput: {
  //             multi: false,
  //             showClear: true,
  //             optionLabel: 'label',
  //             optionValue: 'value',
  //             items: getEnumOptions(Field.TypeEnum),
  //             badgeView: true,
  //             appendTo: 'body',
  //           },
  //         },
  //         dataType: DataTypeEnum.Badge,
  //         showLabelInViewMode: false,
  //       },
  //     },
  //     {
  //       componentType: BasicInputComponent,
  //       options: {
  //         label: 'Key',
  //         name: 'name',
  //         control: new FormControl('', Validators.required),
  //         dataType: DataTypeEnum.Text,
  //         showLabelInViewMode: false,
  //       },
  //     },
  //     // {
  //     //     componentType: SwitchInputComponent,
  //     //     options: {
  //     //         label: 'Is Required',
  //     //         name: "required",
  //     //         control: new FormControl(false),
  //     //         dataType: DataTypeEnum.Boolean,
  //     //         showLabelInViewMode:false
  //     //     },
  //     // },
  //     {
  //       componentType: BasicInputComponent,
  //       options: {
  //         label: 'Field Options',
  //         name: 'options',
  //         control: new FormControl(null),
  //         dataType: DataTypeEnum.Text,
  //         inputOptions: {
  //           dynamicTypeOptionsInput: {
  //             fieldType: null,
  //             fieldTypeControl: null,
  //           },
  //           // linkedControlName:'type',
  //         },
  //         showLabelInViewMode: false,
  //         hideInFormRepeater: true,
  //       },
  //     },
  //   ];
  // }
}
